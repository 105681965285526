'use strict'
import VueLogger from 'vuejs-logger';
let instance = null

const loggingOptions = {
    isEnabled: true,
    logLevel: process.env.NODE_ENV === 'production' ? 'error' : 'debug',
    stringifyArguments: false,
    showLogLevel: true,
    showMethodName: false,
    separator: '|',
    showConsoleColors: true
}

Vue.use(VueLogger, loggingOptions);

class FS {
    constructor() {
        if (!instance) {
            instance = this;
        }
        this.data = {}
        this.data.token = {}
        this.data.platformName = {}
        this.data.company = {}
        this.data.company.name = {}
        this.data.account = {}
        this.headers = {}
        this.company = {}
        this.websocket = {}
        this.init()
        this.enablePanels()
        this.options = {
            isProduction: process.env.NODE_ENV === 'production'
        }
        this.options.logging = loggingOptions
        this.log = function (message) {
            Vue.$log.debug(message)
        }
        this.loggingOptions = loggingOptions
    }
    enablePanels() {
        $ = window.$
        $(".collapse").on('shown.bs.collapse', function () {
            let active = $(this).attr('id');
            let panels = localStorage.panels === undefined ? [] : JSON.parse(localStorage.panels);
            let elementIndex = $.inArray(active, panels);
            if (elementIndex !== -1) //check the array
            {
                panels.splice(elementIndex, 1); //remove item from array
            }
            localStorage.panels = JSON.stringify(panels);
        });

        $(".collapse").on('hidden.bs.collapse', function () {
            let active = $(this).attr('id');
            let panels = localStorage.panels === undefined ? [] : JSON.parse(localStorage.panels);
            if ($.inArray(active, panels) == -1) //check that the element is not in the array
            {
                panels.push(active);
            }
            localStorage.panels = JSON.stringify(panels); //save array on localStorage
        });
        let panels = localStorage.panels === undefined ? [] : JSON.parse(localStorage.panels); //get all panels
        for (var i in panels) { //<-- panel is the name of the cookie
            if ($("#" + panels[i]).hasClass('collapse')) // check if this is a panel
            {
                $("#" + panels[i]).collapse("hide");
            }
        }
    }

    init() {
        if (window.FS != null) {
            this.websocket = window.FS.websocket;
            this.company = window.FS.company;
            this.data = window.FS.data;
            this.loader = $(".se-pre-con")
        } else {
            this.headers['X-CSRF-TOKEN'] = document.querySelector('meta[name="_csrf"]').content;
            this.data.platformName = document.querySelector("meta[name='_platform_name']").content;
            this.company.name = document.querySelector("meta[name='_company_name']").content;
            this.company.id = document.querySelector("meta[name='_company_id']").content;
        }
        this.data.token = document.querySelector('meta[name="_csrf"]').content;

        if (!String.prototype.format) {
            String.prototype.format = function () {
                let args = arguments;
                return this.replace(/{(\d+)}/g, function (match, number) {
                    return typeof args[number] != 'undefined' ? args[number] : match;
                });
            };
        }


    }
}

export default (new FS);

