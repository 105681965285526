'use strict';
import Vue from 'vue'
import VueColor from 'vue-color'
import Vue2Dropzone from 'vue2-dropzone'
import axios from 'axios'
import FS from './app'
import PrettyCheckbox from 'pretty-checkbox-vue'

import "../../../../node_modules/pretty-checkbox/dist/pretty-checkbox.css"
import '../../../../node_modules/vue2-dropzone/dist/vue2Dropzone.min.css'

$ = window.$;

let clientId = $("div.content-wrapper").data("company_id");

axios.defaults.headers.common['X-CSRF-TOKEN'] = FS.data.token;

Vue.use(PrettyCheckbox);

let vm = new Vue({
    el: '#client-settings',
    components: {
        'sketch-picker': VueColor.Sketch,
        'vueDropzone': Vue2Dropzone
    },
    data: {
        accessList: {
            headers: [
                {
                    orderable: false,
                    className: 'select-checkbox',
                    targets: -1,
                    data: null,
                    defaultContent: ""
                },
                {
                    data: 'id',
                    title: "ID",
                    className: 'no-edit',
                    orderable: true,
                    searchable: true,
                    visible: false
                },
                {
                    data: 'priority',
                    title: 'Priority',
                    orderable: true,
                    searchable: true,
                    visible: false
                },
                {
                    data: 'allow',
                    title: 'Allow',
                    class: 'text-center',
                    editField: 'allow',
                    render: function (v) {
                        if (v) {
                            return "<i class=\"fad fa-2x fa-lock text-success\"></i>"
                        } else {
                            return "<i class=\"fad fa-2x fa-lock text-danger\"></i>"
                        }
                    },
                    orderable: true,
                    searchable: true,
                },
                {
                    data: 'notation',
                    title: 'CIRD Notation',
                    type: "ip-address",
                    orderable: true,
                    searchable: true,
                },
                {
                    data: 'address',
                    title: 'IP Address',
                    type: "ip-address",
                    orderable: true,
                    searchable: true,
                },
                {
                    data: 'netmask',
                    title: 'Netmask',
                    type: "ip-address",
                    orderable: true,
                    searchable: true,
                }

            ],
            editorHeaders: [
                {
                    name: 'id',
                    type: 'hidden'
                },
                {
                    name: 'priority',
                    label: 'Priority',
                    multiEditable: false,
                    attr: {
                        type: "number",
                        min: 2,
                        max: 9999,
                        required: true
                    },
                    def: 2
                },
                {
                    name: 'allow',
                    label: 'Allow',
                    type: "select",
                    options: [
                        {label: "Allow", value: true},
                        {label: "Deny", value: false}
                    ],
                    multiEditable: true
                },
                {
                    name: 'notation',
                    label: 'CIRD Notation',
                    attr: {
                        pattern: "((^|\.)((25[0-5])|(2[0-4]\d)|(1\d\d)|([1-9]?\d))){4}/(?:\d|[12]\d|3[012])$",
                        placeholder: "0.0.0.0/0"
                    },
                    multiEditable: false
                },
                {
                    name: 'address',
                    label: 'IP Address',
                    attr: {
                        pattern: "((^|\.)((25[0-5])|(2[0-4]\d)|(1\d\d)|([1-9]?\d))){4}$",
                        placeholder: "0.0.0.0"
                    },
                    multiEditable: true
                },
                {
                    name: 'netmask',
                    label: 'Netmask',
                    attr: {
                        pattern: "((^|\.)((25[0-5])|(2[0-4]\d)|(1\d\d)|([1-9]?\d))){4}$",
                        placeholder: "0.0.0.0"
                    },
                    multiEditable: true
                }

            ],
            rows: [],
            dtHandle: null,
            dtEditor: null
        },
        accessListData: [],
        companySettings: {},
        message: {},
        account: {},
        search: '',
        css: {
            "secondary": "#00417A",
            "topnavigation": "#5C6893",
            "sidenavigationfont": "#555",
            "logobackground": "#FFF",
            "background": "#FFF",
            "topnavigationfont": "#555",
            "primary": "#FFF",
            "sidenavigation": "#FFF",
            "subnavigationprimaryfont": "#000",
            "subnavigationsecondaryfont": "#000",
            "tableselected": "#e2e2e2"
        },
        cssBtnDisabled: false,
        settingsBtnDisabled: false,
        logoDropzoneOptions: {
            url: '/branding/upload',
            maxFilesize: 1,
            maxFiles: 1,
            thumbnailMethod: 'contain',
            acceptedFiles: "image/*",
            addRemoveLinks: true,
            dictDefaultMessage: '<strong>Upload a Logo</strong><br/><small class="muted">From your computer</small>',
            headers: {
                'X-CSRF-TOKEN': FS.data.token,
            }
        },
        cssDropzoneOptions: {
            url: '/branding/upload',
            maxFilesize: 1,
            maxFiles: 1,
            thumbnailMethod: 'contain',
            acceptedFiles: "text/*",
            addRemoveLinks: true,
            dictDefaultMessage: '<strong>Upload custom CSS</strong><br/><small class="muted">From your computer</small>',
            headers: {
                'X-CSRF-TOKEN': FS.data.token,
            }
        },
        faviconDropzoneOptions: {
            url: '/branding/upload',
            maxFilesize: 1,
            maxFiles: 1,
            thumbnailMethod: 'contain',
            acceptedFiles: "image/*",
            addRemoveLinks: true,
            dictDefaultMessage: '<strong>Upload a Favicon</strong><br/><small class="muted">From your computer</small>',
            headers: {
                'X-CSRF-TOKEN': FS.data.token,
            }
        },
        logoFile: [],
        faviconFile: [],
        cssFile: [],
        companyType: $('.content-wrapper').data("company_type"),
        errors: [],
        provisionFolderUnique: false
    },
    computed: {
        type() {
            return typeof this.handler !== 'undefined' ? 'button' : 'submit'
        },
        classes() {
            return [
                `btn btn-primary btn-sm`,
                this.loading && !this.success ? 'loading' : null,
                this.success ? 'success' : null
            ]
        },
        companyIsClient() {
            return this.companyType.toLowerCase() === "client"
        }

    },
    methods: {
        cssOnClick: function () {
            this.toggleCSSSubmitBtn();
            let stylesheet = '/branding/skin.css';

            axios.post("/clients/setCssColours?pk=" + clientId, this.css).then(
                function (r) {
                    if (r.data.status === "success") {
                        window.$.growl.notice({
                            message: r.data.msg
                        });
                        $('#customCSS').attr('href', stylesheet)
                    } else {
                        window.$.growl.warning({
                            message: r.data.msg
                        })
                    }
                    $('#customCSS').attr('href', stylesheet)
                });
            this.toggleCSSSubmitBtn()
        },
        settingsOnClick: function (event) {
            event.preventDefault();
            this.toggleSettingSubmitBtn();
            axios.post("/api/v2/company/" + clientId + "/settings",
                this.companySettings).then(function (r) {
                FS.log(r);
                if (r.status === 200) {
                    window.$.growl.notice({
                        message: "Updated"
                    })
                } else {
                    window.$.growl.warning({
                        message: "Error"
                    })
                }
            });
            this.toggleSettingSubmitBtn()
        },
        updatePrimary(value) {
            this.css.primary = value.hex
        },
        updateSecondary(value) {
            this.css.secondary = value.hex
        },
        updateTopNavigation(value) {
            this.css.topnavigation = value.hex
        },
        updateTopNavigationFont(value) {
            this.css.topnavigationfont = value.hex
        },
        updateSideNavigation(value) {
            this.css.sidenavigation = value.hex
        },
        updateSideNavigationFont(value) {
            this.css.sidenavigationfont = value.hex
        },
        updateSubNavigationPrimaryFont(value) {
            this.css.subnavigationprimaryfont = value.hex
        },
        updateSubNavigationSecondaryFont(value) {
            this.css.subnavigationsecondaryfont = value.hex
        },
        updateLogoBackground(value) {
            this.css.logobackground = value.hex
        },
        updateBackground(value) {
            this.css.background = value.hex
        },
        updateTableSelected(value) {
            this.css.tableselected = value.hexupdateTableSelected
        },
        toggleCSSSubmitBtn() {
            this.cssBtnDisabled = !this.cssBtnDisabled
        },
        toggleSettingSubmitBtn() {
            this.settingsBtnDisabled = !this.settingsBtnDisabled
        },
        logoSendingEvent(file, xhr, formData) {
            formData.append('uuid', file.upload.uuid);
            formData.append('type', 'png');
            formData.append('companyId', clientId)
        },
        faviconSendingEvent(file, xhr, formData) {
            formData.append('uuid', file.upload.uuid);
            formData.append('type', 'ico');
            formData.append('companyId', clientId)
        },
        cssSendingEvent(file, xhr, formData) {
            formData.append('uuid', file.upload.uuid);
            formData.append('type', 'css');
            formData.append('companyId', clientId)
        },
        sendSuccess(file, response) {
            if (response.status.toLowerCase() === "success") {
                $.growl.notice({
                    message: response.description
                })
            }
            this.checkPreview(file, null)
        },
        logoDelete(file, error, xhr) {
            let data = {};
            data.type = 'png';
            this.fileDelete(data, file)
        },
        faviconDelete(file, error, xhr) {
            let data = {};
            data.type = 'ico';
            this.fileDelete(data, file)
        },
        cssDelete(file, error, xhr) {
            let data = {};
            data.type = 'css';
            this.fileDelete(data, file)
        },
        fileDelete(data, file) {
            data.uploadDTO = {}
            data.uploadDTO.companyId = clientId;
            data.uploadDTO.uuid = file.uuid;
            data.uploadDTO.name = file.name;
            data.uploadDTO.size = file.size;
            axios.delete("/branding/delete?name=" + file.name + "&companyId=" + clientId + "&type=" + file.type ).then(
                function (response) {
                    FS.log(response);
                    if (response.data.status === "success") {
                        $.growl.notice({
                            message: response.data.description
                        })
                    } else {
                        $.growl.warning({
                            message: response.data.description
                        })
                    }
                })
        },
        checkPreview(file, dataUrl) {
            FS.log(file);
            let thumbnail = $(file.previewElement).find(".dz-image img");
            if (file.type.toLowerCase().indexOf("image") < 0
                && file.type.toLowerCase().indexOf("png") < 0
                && file.type.toLowerCase().indexOf("ico") < 0) {
                thumbnail.attr("src", '/resources/img/file-icon-28038.png')
            }
            thumbnail.css({
                "width": "200px",
                "height": "200px",
                "object-fit": "contain"
            })

        },
        addExistingFiles(v) {
            switch (v.type) {
                case "png":
                    FS.log(this.$data.logoFile.includes(v.url));
                    FS.log(this.$data.logoFile);
                    FS.log(v.url);
                    FS.log("file equals: " + v.name);
                    if (!this.$data.logoFile.includes(v.url)) {
                        this.$data.logoFile.push(v.url);
                        this.$refs.logoDropzone.manuallyAddFile(v, window.location.origin + v.url)
                    }
                    break;
                case "css":
                    if (!this.$data.cssFile.includes(v.url)) {
                        this.$data.cssFile.push(v.url);
                        this.$refs.cssDropzone.manuallyAddFile(v, v.url)
                    }
                    break;
                case "ico":
                    if (!this.$data.faviconFile.includes(v.url)) {
                        this.$data.faviconFile.push(v.url);
                        this.$refs.faviconDropzone.manuallyAddFile(v, v.url)
                    }
                    break;
                default:
            }
        },
        selectTab: function (tab) {
            let vm = this;
            switch (tab) {
                case "branding":
                    axios.get("/api/v2/company/" + clientId + '/branding').then(
                        function (response) {
                            response.data.forEach(function (v) {
                                vm.addExistingFiles(v)
                            });
                        });
                    break;
                case "css":
                    axios.get("/api/v2/company/" + clientId + '/css').then(
                        function (response) {
                            response.data.forEach(function (v) {
                                vm.css[v.type] = v.value
                            });
                        });
                    break;
                case "settings":
                    axios.get("/api/v2/company/" + clientId + '/settings').then(
                        function (response) {
                            vm.companySettings = response.data;
                            vm.companySettings.origionalProvisionFolder = response.data.provisionFolder;
                        });
                    break;
                case "access":
                    axios.get(
                        '/api/v2/company/' + clientId + '/device/access').then(
                        function (response) {
                            vm.accessListData = response.data;
                        });
                    break;
                default:
            }
        },
        provisionFolderUniqueCheck: function () {
            let vm = this;
            if (vm.companySettings.origionalProvisionFolder
                !== vm.companySettings.provisionFolder) {
                FS.log("Check Provision Folder");
                axios.get("/api/v2/company/settings/check/provisionFolder/"
                    + vm.companySettings.provisionFolder).then(
                    function (response) {
                        vm.provisionFolderUnique = response.data
                    });
            } else {
                vm.provisionFolderUnique = false
            }
        },
        reloadCSS: function () {
            for (let link of document.querySelectorAll(
                "link[id=customCSS]")) {
                link.href = link.href.replace(/\?.*|$/,
                    "?ts=" + new Date().getTime())
            }
        }
    },
    mounted() {
        window.FS = FS;
        let vm = this;
        let table = $("#accessList");

        vm.accessList.dtEditor = new $.fn.dataTable.Editor({
            ajax: function (method, url, d, successCallback, errorCallback) {
                let output = {data: []};
                let data = [];
                $.each(d.data, function (key, value) {
                    value.id = key;
                    data[key] = value
                });
                if (d.action === 'create') {
                    axios.post(
                        '/api/v2/company/' + clientId + '/device/access/list',
                        data)
                    .then(function (response) {
                        if (response.status === 200) {
                            response.data.forEach(function (value, index, arr) {
                                output.data.push(value);
                                vm.accessListData.push(value)
                            });
                            $.growl.notice({
                                message: "Success"
                            })
                        } else {
                            $.growl.warning({
                                message: response.data.description != null
                                    ? response.data.description : "Failed"
                            })
                        }
                    })
                    .catch(function (error) {
                        $.growl.warning({
                            message: error.response.data.description != null
                                ? error.response.data.description : "Failed"
                        });
                        vm.errors.push(error)
                    })
                } else if (d.action === 'edit') {
                    $.each(d.data, function (key, value) {
                        $.each(vm.accessListData, function (k, v) {
                            if (v.id === parseInt(value.id)) {
                                if (value.notation !== v.notation) {
                                    value.netmask = null;
                                    value.address = null
                                } else {
                                    value.notation = null
                                }
                            }
                        })
                    });

                    axios.post(
                        '/api/v2/company/' + clientId + '/device/access/list',
                        data)
                    .then(function (response) {
                        if (response.status === 200) {
                            response.data.forEach(function (value, index, arr) {
                                $.each(vm.accessListData, function (k, v) {
                                    if (value.id === v.id) {
                                        v.notation = value.notation;
                                        v.netmask = value.netmask;
                                        v.address = value.address;
                                        v.priority = value.priority;
                                        v.allow = value.allow
                                    }
                                })
                            });
                            $.growl.notice({
                                message: "Success"
                            })
                        } else {
                            $.growl.warning({
                                message: response.data.description != null
                                    ? response.data.description : "Failed"
                            })
                        }
                    })
                    .catch(function (error) {
                        $.growl.warning({
                            message: error.response.data.description != null
                                ? error.response.data.description : "Failed"
                        });
                        vm.errors.push(error)
                    })
                } else if (d.action === 'remove') {
                    $.each(data, function (key, value) {
                        if (value != null) {
                            let elmId = value.id;
                            if (elmId !== parseInt(0)) {
                                axios.delete('/api/v2/company/' + clientId
                                    + '/device/access/' + elmId)
                                .then(function (response) {
                                    if (response.status === 200) {
                                        $.growl.notice({
                                            message: response.data.description
                                            != null ? response.data.description
                                                : "Success"
                                        });
                                        $.each(vm.accessListData,
                                            function (key, value) {
                                                if (value.id === elmId) {
                                                    vm.accessListData.splice(
                                                        key, 1)
                                                }
                                            })
                                    } else {
                                        $.growl.warning({
                                            message: response.data.description
                                            != null ? response.data.description
                                                : "Failed"
                                        })
                                    }
                                })
                                .catch(function (error) {
                                    $.growl.warning({
                                        message: error.response.data.description
                                        != null
                                            ? error.response.data.description
                                            : "Failed"
                                    });
                                    vm.errors.push(error)
                                })
                            }
                        }
                    })

                }
                // Show Editor what has changed
                successCallback(output)
            },
            idSrc: 'id',
            table: table,
            fields: vm.accessList.editorHeaders
        });

        vm.accessList.dtHandle = table.DataTable({
            // Specify whatever options you want, at a minimum these:
            columns: vm.accessList.headers,
            serverSide: false,
            data: vm.accessListData,
            colReorder: true,
            paging: true,
            searching: true,
            info: true,
            responsive: true,
            deferRender: true,
            select: {
                style: 'os',
                selector: 'td:first-child'
            },
            processing: true,
            buttons: [
                {
                    extend: 'colvis',
                    text: '<i class="fad fa-line-columns"></i>',
                    className: 'btn-primary'
                },
                {
                    extend: "create",
                    editor: vm.accessList.dtEditor,
                    className: 'btn-primary',
                    text: '<i class="fad fa-plus"></i>'
                },
                {
                    extend: "edit",
                    editor: vm.accessList.dtEditor,
                    className: 'btn-primary',
                    text: '<i class="fad fa-pencil"></i>'
                },
                {
                    extend: "remove",
                    editor: vm.accessList.dtEditor,
                    className: 'btn-primary',
                    text: '<i class="fad fa-trash"></i>'
                },
                {
                    extend: "excel",
                    className: 'btn-primary',
                    filename: FS.data.platformName + '-' + FS.data.company.name
                        + '-product-categories',
                    title: null,
                    text: '<i class="fad fa-file-excel"></i>'
                },
                {
                    className: 'btn-primary',
                    text: '<i class="fad fa-download"></i>',
                    action: function () {
                        location.href = "/api/v2/order/export/productgroup";
                        return false
                    }
                },
                {
                    className: 'btn-primary',
                    text: '<i class="fad fa-upload"></i>',
                    action: function () {
                        let fileSelector = $(
                            '<input type="file" onchange="handleFile(this, \'/api/v2/order/import/productgroup\')" />');
                        fileSelector.click();
                        return false
                    }
                }],

            stateSave: true,
            "dom": "<'row tableHeader no-padding'<'col-sm-4 pad'B><'col-sm-3 pad'i><'col-sm-5 pad'f>>"
                + "<'row'<'col-sm-12 'tr>>"
                + "<'row'<'col-sm-5'l><'col-sm-7 no-padding'p>>"
        }).on('draw.dt', function (e, settings, json, xhr) {
            $(".company-filter").click(function () {
                let id = $(this).data("company-id");
                vm.viewCustomers(id)
            });
            vm.$forceUpdate()
        }).on('stateSaveParams.dt', function (e, settings, data) {
            data.search.search = "";
            data.start = 0
        });

        table.on('click',
            'tbody td:not(.child, .control, .select-checkbox, .no-edit), tbody span.dtr-data',
            function (e) {
                vm.accessList.dtEditor.inline(this, {
                    submit: 'allIfChanged',
                    buttons: [{
                        className: 'btn-primary',
                        label: '<i class="fad fa-check" aria-hidden="true"></i>',
                        fn: function () {
                            vm.accessList.dtEditor.submit()
                        }
                    }]

                })
            });

        vm.accessList.dtEditor.on('preSubmit', function (e, o, action) {
                if (action !== 'remove') {
                    let priority = this.field('priority');
                    let allow = this.field('allow');
                    let netmask = this.field('netmask');
                    let address = this.field('address');
                    let notation = this.field('notation');
                    let id = this.field('id');
                    if (!priority.isMultiValue()) {
                        if (!priority.val()) {
                            priority.error('A Priority must be given')
                        }
                    }
                    if (!allow.val()) {
                        allow.val(true);
                        if (id.val()) {
                            $.each(vm.accessListData, function (k, v) {
                                if (id.val() === v.id) {
                                    allow.val(v.allow)
                                }
                            })
                        }
                    }
                    if (address.val() && !ValidateIPAddress(address.val())) {
                        address.error('IP address is invalid')
                    }
                    if (netmask.val() && !ValidateIPAddress(netmask.val())) {
                        netmask.error('Netmask is invalid')
                    }
                    if (!notation.isMultiValue()) {
                        if (!notation.val() && !address.val() && !netmask.val()) {
                            notation.error(
                                'Either IP and Netmask or Notation must be provided');
                            address.error(
                                'Either IP and Netmask or Notation must be provided');
                            netmask.error(
                                'Either IP and Netmask or Notation must be provided')
                        }
                    }
                }
                if (this.inError()) {
                    return false
                }
            }
        );

        vm.accessList.dtEditor.field('allow').input().addClass(
            'form-control datatTableEditorSelect');

    },
    created(){
        let vm = this;
        axios.get("/api/v2/company/" + clientId + '/css').then(
            function (response) {
                response.data.forEach(function (v) {
                    vm.css[v.type] = v.value
                });
            });
    },
    watch: {
        accessListData: {
            handler: function (val, oldVal) {
                let vm = this;
                vm.accessList.dtHandle.clear();
                vm.accessList.dtHandle.rows.add(vm.accessListData).draw()
            }
            ,
            deep: true
        }
    }
});

/**
 * @return {boolean}
 */
function ValidateIPAddress(ipAddress) {
    return /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/.test(
        ipAddress);

}

function isEmpty(obj) {
    for (let prop in obj) {
        if (obj.hasOwnProperty(prop)) {
            return false;
        }
    }
    return JSON.stringify(obj) === JSON.stringify({});
}

function debugLog(message) {
    if (FS.debug) {
        FS.log(message)
    }
}
